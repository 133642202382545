<template>
  <v-autocomplete
    outlined
    :items="items"
    label="Wybierz pośrednika"
    multiple
    v-model="value"
    :item-text="textProperty"
    :item-value="valueProperty"
    placeholder="Wybierz pośrednika"
    append-icon="mdi-chevron-down"
    dense
    clearable
    @click:clear="clearValue"
  >
    <template
      #selection="{ item, index }"
    >
      <MultiselectChoices
        :index="index"
        :label="item[textProperty]"
        :array-length="valueTemp ? valueTemp.length : 0"
        :visible-items="1"
        :max-length="30"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import get from 'lodash/get'
import MultiselectChoices from '../../Elements/MultiselectChoices.vue'

export default {
  components: {
    MultiselectChoices
  },
  data() {
    return {
      valueTemp: null
    }
  },
  mixins: [inputMixin],
  mounted() {
    this.valueTemp = this.value
  },
  computed: {
    items () {
      return this.$store.state.middlemen.items
    },
    textProperty () {
      return 'name'
    },
    valueProperty () {
      return 'id'
    },
    value: {
      get () {
        const currentValue = get(this.data, this.field.value, '')
        return currentValue.map(item => item[this.valueProperty])
      },
      set (value) {
        this.$emit('update', { [this.field.name]: value })
        this.valueTemp = value
      }
    }
  },
  methods: {
    clearValue () {
      this.$nextTick(() => { this.value = null })
    }
  }
}
</script>
